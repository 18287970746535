import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Stack, Main } from '@layout';
import PageTitle from '@components/PageTitle';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';
import ContactForm from '../@elegantstack/flow-ui-components/ContactForm/ContactForm';
import styled from '@emotion/styled';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { Text, Link, Box } from 'theme-ui';

const styles = {
  image: {
    width: `73px`,
    marginBottom: `1em`,
  },
  boldSubtitle: {
    fontWeight: 700,
    color: ` #323233`,
    textDecoration: `none`,
    fontsize: `1em`,
    margin: 0,
  },
};

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  min-height: auto;
  padding-bottom: 50px;
`;

const ContactRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 549px) {
    display: grid;
  }
`;

const ContactColumn = styled.div`
  width: 33.3%;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  @media (max-width: 549px) {
    width: auto;
    margin-bottom: 30px;
  }
`;

const InfoBanner = ({ contact }) => {
  return (
    <>
      <Text variant='forms.title'>{contact.text_1.text}</Text>
      <ContactContainer>
        <ContactRow>
          {contact.images.map((image, index) => {
            return (
              <ContactColumn key={index}>
                <Link href={image.link}>
                  <Img
                    image={getImage(image.src.ImageSharp)}
                    alt={image.alt}
                    style={styles.image}
                  />
                  <Text variant='forms.title'>{image.value[0]}</Text>
                  <Link variant='forms.subtitle' href={image.link}>
                    {image.value[1]}
                  </Link>
                </Link>
              </ContactColumn>
            );
          })}
        </ContactRow>
      </ContactContainer>
    </>
  );
};

const Contact = (props) => {
  const { allBlockContent, site } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const contact = content['contact'];
  return (
    <Layout {...props} background='white'>
      <Seo title={contact.kicker.text} />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header={contact.title.text}
            subheader={contact.description.text}
          />
          <Divider />
          <ContactForm
            titles={contact.collection}
            apiUrl={site.siteMetadata.api.ums}
          />
          <Divider />
          <InfoBanner contact={contact} />
        </Main>
      </Stack>
    </Layout>
  );
};

export const query = graphql`
  query siteContactBlockContent($blocksPaths: [String]!) {
    site {
      siteMetadata {
        api {
          ums
        }
      }
    }
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: { in: ["contact", "footer"] }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default Contact;
