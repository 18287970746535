import { useCallback, useState } from "react";

/** Backend mapped its responses like this */
export enum BE_RESPONSE {
  "error" = -1,
  "warning" = 0,
  "success" = 1, // A successful backend response can be any number > 0
}

type FetchStatus = "idle" | "pending" | "success" | "error";

export function useSendTicket() {
  const [backendResponse, setBackendResponse] = useState<BE_RESPONSE>();
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>("idle");

  const send = (url: string, init: RequestInit) => {
    setFetchStatus("pending");

    fetch(url, init)
      .then((r) => {
        if (r.status !== 200) {
          throw new Error(r.statusText);
        }

        return r.json();
      })
      .then((s: number) => {
        setBackendResponse(s);
        setFetchStatus("success");
      })
      .catch((e) => {
        setBackendResponse(-1);
        setFetchStatus("error");
      })
      .finally(() => setFetchStatus("idle"));
  };

  return { send, backendResponse, fetchStatus };
}
