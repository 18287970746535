import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

/**
 *  This component wrap the component from 'react-google-recaptcha'
 *  library to configure it with the API key and expose its onChange method
 */
export const Captcha = forwardRef((props, ref) => {
  const handleChange = (token) => {
    if (props.onChange) props.onChange(token);
    return;
  };

  return (
    <>
      <ReCAPTCHA
        sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_KEY}
        ref={ref}
        size={props.size}
        onChange={(token) => handleChange(token)}
      />
    </>
  );
});
