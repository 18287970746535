import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Label,
  Input,
  Textarea,
  Button,
  Message,
  Spinner,
  Text,
} from "theme-ui";
import { Captcha } from "../../../components/Captcha";
import { useSendTicket } from "./use-send-ticket";

const styles = {
  form: {
    padding: `20px`,
  },
};

const ContactForm = ({ titles, apiUrl }) => {
  const { send, backendResponse, fetchStatus } = useSendTicket();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const captchaRef = useRef(null);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    console.log("submit form");
    /**
     * Trigger the captcha challenge, NOTE: It does not always happen, it's up
     * to the google api, as it somehow caches it.
     */
    const captchaValue = await captchaRef.current.executeAsync();
    console.log(captchaValue);

    /**
     * The captcha library is really terribly made, so we need to get the captcha value imperatively
     * please give a look at the repo to have a better understanding.
     * See https://github.com/dozoisch/react-google-recaptcha
     */
    if (captchaValue) {
      send(`${apiUrl}support/ticket/public`, {
        method: "POST",
        body: JSON.stringify({ name, email, subject, content }),
        headers: {
          "Content-Type": "application/json",
          "Captcha-Token": captchaValue,
        },
      });
    }

    captchaRef.current.reset();
  };

  console.log("@@@ fetch status: ", fetchStatus);
  console.log("@@@ backend response: ", backendResponse);

  const renderMessage = useCallback(() => {
    if (backendResponse !== undefined) {
      return (
        <Message
          variant={
            backendResponse === 0
              ? "warning"
              : backendResponse > 0
              ? "success"
              : "error"
          }
        >
          {
            titles[backendResponse === 0 ? 7 : backendResponse > 0 ? 6 : 8]
              .title.text
          }
        </Message>
      );
    }
  }, [backendResponse]);

  return (
    <Box variant="forms.form">
      <form onSubmit={handleSubmitForm} style={styles.form}>
        {renderMessage()}
        <Text variant="forms.title">{titles[0].title.text}</Text>
        <Box variant="forms.row">
          <Box variant="forms.column">
            <Label htmlFor="contact-form-name">{titles[1].title.text}</Label>
            <Input
              type="text"
              id="contact-form-name"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </Box>
        <Box variant="forms.row">
          <Box variant="forms.column">
            <Label htmlFor="contact-form-email">{titles[2].title.text}</Label>
            <Input
              type="email"
              placeholder="email@example.com"
              id="contact-form-email"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
        </Box>
        <Box variant="forms.row">
          <Label htmlFor="contact-form-subject">{titles[3].title.text}</Label>
          <Input
            type="text"
            id="contact-form-subject"
            name="subject"
            required
            onChange={(e) => setSubject(e.target.value)}
          />
        </Box>
        <Box variant="forms.row">
          <Label htmlFor="contact-form-message">{titles[4].title.text}</Label>
          <Textarea
            name="content"
            id="contact-form-message"
            onChange={(e) => setContent(e.target.value)}
          />
        </Box>

        <Captcha ref={captchaRef} size={"invisible"} />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="grecaptcha"
            variant={
              fetchStatus === "pending" || backendResponse >= 0
                ? "disabled"
                : "primary"
            }
            disabled={fetchStatus === "pending" || backendResponse >= 0}
            type="submit"
            required
          >
            {titles[5].title.text}{" "}
            {fetchStatus === "pending" && <Spinner size="20" />}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ContactForm;
